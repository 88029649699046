<template>
  <v-col
    v-if="show"
    cols="12"
    md="6"
  >
    <card
      :class="isXs ? 'pa-0 ma-0' :'pa-1 ma-5'"
      :style="isXs ? '':'min-height: 520px'"
      outlined
    >
      <v-card-title>
        <v-icon
          large
          left
          :color="statusObj(title).color"
        >
          fa fa-file-text-o
        </v-icon>
        <span
          class="text-h4 font-weight-black font-weight-light"
          :color="statusObj(title).color"
        >
          {{ formName }}
        </span>
      </v-card-title>
      <v-alert
        text
        close-icon="fa fa-close"
        icon="fa4 fa-bar-chart"
        dense
        class="mt-1 mt-sm-4 mt-md-4 mt-lg-4 mt-xl-4 mb-0 mb-sm-4 mb-md-4 mb-lg-4 mb-xl-4 py-0 py-sm-1 py-md-1 py-lg-1 py-xl-1"
        :color="statusObj(title).color"
      >
        有<strong> {{ projectTotal }}</strong>个项目{{ title }}.
      </v-alert>
      <v-simple-table>
        <thead>
          <tr :class="isXs ? 'titles' : ''">
            <th>项目名称</th>
            <th class="text-right">
              操作
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(project, index) in projectList"
            :key="index"
            :class="isXs ? 'names' : ''"
          >
            <td>{{ project.name }}</td>
            <td class="text-right">
              <v-btn
                class="px-2 ml-1"
                min-width="0"
                small
                color="success"
                @click="see(project)"
              >
                <v-icon
                  small
                  class="mr-2"
                >
                  fa4 fa-eye
                </v-icon>
                查看
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-card-actions>
        <v-btn
          block
          color="blue darken-2"
          outlined
          @click="jump()"
        >
          查看更多
        </v-btn>
      </v-card-actions>
    </card>
  </v-col>
</template>

<script>
  export default {
    props: {
      aStatusList: {
        type: Number,
        default: 0,
      },
      statusList: {
        type: Number,
        default: 0,
      },
      formId: {
        type: String,
        default: null,
      },
      formName: {
        type: String,
        default: '项目库',
      },
      _from: {
        type: String,
        default: 'org',
      },
      title: {
        type: String,
        default: null,
      },
      projectStatus2: {
        type: String,
        default: null,
      },
      path: {
        type: String,
        default: null,
      },
      isDocking: {
        type: String,
        default: null,
      },
    },
    data () {
      return {
        // 待审核
        show: true,
        projectList: [],
        projectTotal: 0,
        formIds: '',
        searchParame: {
          page: 0,
          size: 5,
        },
        isEdit: false,
        // 重点推介
        tjFormId: 'defdcd74-fe79-4906-b3aa-dbdb0ce55afe',
        // 外商寻求
        wqFormId: 'eeadced3-7c53-4e4d-9f17-a334a28e10ad',
        isXs: false,
      };
    },
    watch: {
      formId (val) {
        this.getAuditProject();
      },
      '$vuetify.breakpoint.name': {
        handler (name) {
          if (name === 'xs') {
            this.isXs = true
          } else if (name === 'sm') {
            this.isXs = false
          } else {
            this.isXs = false
          }
        },
        deep: true,
        // 代表在wacth里声明了这个方法之后立即先去执行handler方法
        immediate: true,
      },
    },
    mounted () {
      this.isEdit = localStorage.isEdit === 'true' || localStorage.isEdit === true
      if (JSON.parse(localStorage.newForms).length) {
        const list = JSON.parse(localStorage.newForms)[0].children;
        const formIds = [];
        list.map((val) => {
          formIds.push(val.id);
        });
        this.formIds = formIds.toString();
      }
      this.getAuditProject();
    },
    methods: {
      // 获取下级提报的待审核项目
      getAuditProject () {
        this.formId && (this.searchParame['formId'] = this.formId)
        this._from && (this.searchParame['_from'] = this._from)
        this.aStatusList && (this.searchParame['aStatusList'] = this.aStatusList)
        this.statusList && (this.searchParame['statusList'] = this.statusList)
        this.projectStatus2 && (this.searchParame['projectStatus2'] = this.projectStatus2)
        this.isDocking && (this.searchParame['isDocking'] = this.isDocking)
        this.$axios
          .get('/reports/query', {
            params: this.searchParame,
          })
          .then((res) => {
            const resData = res.data.data;
            this.projectList = resData.content;
            this.projectTotal = resData.totalElements;
            if (!resData.totalElements) {
            // this.show = false
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      jump () {
        const searchParame = JSON.parse(JSON.stringify(this.searchParame))
        delete searchParame.size
        this.$router.push({
          path: this.path ? this.path : '/list',
          query: searchParame,
        })
      },

      //   :to="`report-details/${project.formId}/${project.id}`"
      see (item) {
        console.log('item', item);
        // 市区，代表处是被授权的
        if (this.path) {
          const isMarket = localStorage.isMarket === 'true';
          const isDeputy = localStorage.isDeputy === 'true'
          this.$router.push({
            name: 'ReportDetails',
            params: {
              formId: item.data.parentFormId,
              id: (isMarket || isDeputy) ? item.data.parentID : item.data.allparentID,
            },
            query: {
              dynamicId: item.id,
            },
          })
        } else {
          this.$router.push({
            name: 'ReportDetails',
            params: {
              formId: item.formId,
              id: item.id,
            },
          })
        }
      },
      statusObj (title) {
        var list = {
          title: '待审核',
          icon: 'mdi-email',
          color: 'blue darken-3',
        }
        switch (title) {
          case '待审核':
            list = {
              title: '待审核',
              icon: 'mdi-email',
              color: 'blue darken-3',
            }
            break;
          case '待完善':
            list = {
              title: '待完善',
              icon: 'mdi-email',
              color: 'red darken-3',
            }
            break;
          case '已通过':
            list = {
              title: '已通过',
              icon: 'mdi-email',
              color: 'success',
            }
            break;
          default:
            break;
        }

        return list
      },
    },
  };
</script>

<style lang="scss" scoped>
.names td{
font-size: 14px !important;
font-family: PingFang SC, PingFang SC-Medium;
color: #333333;
padding: 0 6px !important;
}

.titles th{
opacity: 0.6;
font-size: 13px !important;
font-family: PingFang SC, PingFang SC-Medium;
font-weight: 500;
color: #333333 !important;
padding:0 6px !important
}
::v-deep .v-alert--dense .v-alert__wrapper i{
margin-right:0px
}

</style>
